import React from "react";
// import Peoples from "../../assets/images/people/Laye.png";
import Peoples1 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr.jpg";
import Peoples2 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr2.jpg";
import Peoples3 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr3.jpg";
// import Peoples4 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr4.jpg";
import Peoples5 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr5.jpg";
import Peoples6 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr6.jpg";
// import Peoples7 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr7.jpg";
// import Peoples8 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr8.jpg";
// import NationalImage1 from "../../assets/images/event-details/NationalImage/RakeshSenger.jpg";
import Peoples9 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr9.jpg";
import Peoples10 from "../../assets/images/event-details/NationalAdvisoryBoard/Banish-Dhar.jpeg";
import Peoples11 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr11.jpg";
import Peoples12 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr12.jpg";
// import Peoples13 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr13.jpg";
// import NationalImage7 from "../../assets/images/event-details/NationalImage/Anil-Pandey.jpg";
// import Peoples14 from "../../assets/images/event-details/NatyionalAdvisoryBoard/Dr14.jpg";
// import Peoples15 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr15.jpg";
// import Peoples16 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr16.jpg";
// import Peoples17 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr17.jpg";
// import Peoples18 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr18.jpg";
// import Peoples19 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr19.jpg";
// import Peoples20 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr20.jpg";
import Peoples21 from "../../assets/images/event-details/NationalAdvisoryBoard/Dr21.jpg";

import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import "./PeopleBehindUs.css";
import Col from "react-bootstrap/Col";

export default function NationalAdvisoryBoard() {
  return (
    <Row className="mt-5 doml">
    
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples1} />
          <Card.Body className="pb-0">
            <Card.Title>Dr. Karan Singh</Card.Title>
            <Card.Text className="m-0">Ex. Member of Parliament</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow ">
          <Card.Img variant="top" src={Peoples2} />
          <Card.Body className="pb-0">
            <Card.Title>Prof. Rita Bahuguna Joshi</Card.Title>
            <Card.Text className="m-0">
              Ex. Member of Parliament
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples3} />
          <Card.Body className="pb-0">
            <Card.Title>Dilip Cherian</Card.Title>
            <Card.Text className="m-0">Image Guru & Litigation Landscaping</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples3} />
          <Card.Body className="pb-0">
            <Card.Title>Padmashri Dr. R. N. Singh</Card.Title>
            <Card.Text className="m-0">
            MBBS, M.S. Ortho, FRCS
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples3} />
          <Card.Body className="pb-0">
            <Card.Title>General Shantanu Dayal</Card.Title>
            <Card.Text className="m-0">
              PVSM, UYSM, AVSM, SM and VSM, M in D
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples9} />
          <Card.Body className="pb-0">
            <Card.Title>Prof. Chandramauli Upadhyay(Ph.D)</Card.Title>
            <Card.Text className="m-0">
               Banaras Hindu University
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples6} />
          <Card.Body className="pb-0">
            <Card.Title>Mike Pandey</Card.Title>
            <Card.Text className="m-0">
              Conservationist & Environmentalist
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples11} />
          <Card.Body className="pb-0">
            <Card.Title>Dr. S K Verma</Card.Title>
            <Card.Text className="m-0">
              Senior Advocate, Supreme Court of India
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples11} />
          <Card.Body className="pb-0">
            <Card.Title>Prof. Bhim Singh</Card.Title>
            <Card.Text className="m-0">
            Former Member of the Lok Sabha
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples11} />
          <Card.Body className="pb-0">
            <Card.Title>Amrit Lugun IFS</Card.Title>
            <Card.Text className="m-0">
              Former Ambassador of India to Greece
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      
      

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples21} />
          <Card.Body className="pb-0">
            <Card.Title>Dr. Khwaja Iftikhar Ahmed</Card.Title>
            <Card.Text className="m-0">
              Prominent Indian Muslim Scholar
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples5} />
          <Card.Body className="pb-0">
            <Card.Title>Mrinal Doval</Card.Title>
            <Card.Text className="m-0">Senior Political Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples12} />
          <Card.Body className="pb-0">
            <Card.Title>Shri Arvind Belard</Card.Title>
            <Card.Text className="m-0">Social Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

   
{/*      
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples10} />
          <Card.Body className="pb-0">
            <Card.Title>Banish Dhar</Card.Title>
            <Card.Text className="m-0">
              Global Strategic Advisor, ICPRD
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      */}




















{/* 
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples1} />
          <Card.Body className="pb-0">
            <Card.Title>Dr. Karan Singh</Card.Title>
            <Card.Text className="m-0">Ex. Member of Parliament</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples4} />
          <Card.Body className="pb-0">
            <Card.Title>Chhatrapati Udayanraje Bhosale</Card.Title>
            <Card.Text className="m-0">Maharastra</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples7} />
          <Card.Body className="pb-0">
            <Card.Title>Dr. Mukesh Batra</Card.Title>
            <Card.Text className="m-0">Chairman, Batra Clinic</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples8} />
          <Card.Body className="pb-0">
            <Card.Title>Tanmoy Chakrabarty</Card.Title>
            <Card.Text className="m-0">Group Gov. Affairs Officer, Tata Sons Ltd.</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples13} />
          <Card.Body className="pb-0">
            <Card.Title>P Sudhakar Reddy </Card.Title>
            <Card.Text className="m-0">Telangana</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples14} />
          <Card.Body className="pb-0">
            <Card.Title>Suraj Nangia</Card.Title>
            <Card.Text className="m-0">Founder, Nangia & Co LLP</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage7} />
          <Card.Body className="pb-0">
            <Card.Title>Anil Pandey</Card.Title>
            <Card.Text className="m-0">Press</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage1} />
          <Card.Body className="pb-0">
            <Card.Title>Rakesh Senger</Card.Title>
            <Card.Text className="m-0">Social Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples15} />
          <Card.Body className="pb-0">
            <Card.Title>Rajendra Singh Shekhawat 'Daanta'</Card.Title>
            <Card.Text className="m-0">Social Activist (Rajasthan)</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples16} />
          <Card.Body className="pb-0">
            <Card.Title>Rajesh Sinha</Card.Title>
            <Card.Text className="m-0">Financial Consultant</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples17} />
          <Card.Body className="pb-0">
            <Card.Title>Gurinder Singh Sandhu</Card.Title>
            <Card.Text className="m-0">Social Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples18} />
          <Card.Body className="pb-0">
            <Card.Title>Raj Vaisoha</Card.Title>
            <Card.Text className="m-0">Economic and Foreign Relations Expert</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples19} />
          <Card.Body className="pb-0">
            <Card.Title>Narinder Kumar Bassi</Card.Title>
            <Card.Text className="m-0">Life Skills & Community Aids Educator</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={Peoples20} />
          <Card.Body className="pb-0">
            <Card.Title>Bharat Merchannt</Card.Title>
            <Card.Text className="m-0">
              Chairman, Danish Green Projects
            </Card.Text>
          </Card.Body>
        </Card>
      </Col> */}
    </Row>
  );
}
