import React from "react";
// import Peoples from "../../assets/images/people/Laye.png";
import NationalImage from "../../assets/images/event-details/NationalImage/RajivRanjanSingh.jpg";

import NationalImage2 from "../../assets/images/event-details/NationalImage/Adv-Manish-Kumar-Chaudhary.jpg";
import NationalImage3 from "../../assets/images/event-details/NationalImage/Adv-Ashutosh-Kumar.jpg";
import NationalImage4 from "../../assets/images/event-details/NationalImage/Aditya-Reddy.jpg";
import NationalImage5 from "../../assets/images/event-details/NationalImage/NV-SubbaRaju.jpg";
import NationalImage6 from "../../assets/images/event-details/NationalImage/jeet-singh-rathore-new.jpg";

import NationalImage8 from "../../assets/images/event-details/NationalImage/poonam-kaura.jpg";
import NationalImage9 from "../../assets/images/event-details/NationalImage/Adv-Aditya-Chaudhary.jpg";
import NationalImage10 from "../../assets/images/event-details/NationalImage/Mehul-Shah.jpg";
import NationalImage11 from "../../assets/images/event-details/NationalImage/bhavesh-nandan-jha.jpg";
import NationalImage12 from "../../assets/images/event-details/NationalImage/amish-patel.jpg";
import NationalImage13 from "../../assets/images/event-details/NationalImage/Bharat-Shetty-updated.jpg";
import NationalImage14 from "../../assets/images/event-details/NationalImage/vikram-chauhan.jpg";
import NationalImage15 from "../../assets/images/event-details/NationalImage/tarunbir-singh-sethi.jpg";
import NationalImage16 from "../../assets/images/event-details/NationalImage/Venkita-Subramaniam.jpg";
import NationalImage17 from "../../assets/images/event-details/NationalImage/Upender-Singh.jpg";
import NationalImage18 from "../../assets/images/event-details/NationalImage/Syed-Mahmood-Ahmad.jpg";
import NationalImage19 from "../../assets/images/event-details/NationalImage/Dr-Vishwas-Valvi.jpg";
import NationalImage20 from "../../assets/images/event-details/NationalImage/Varun-Juneja.jpg";
import NationalImage21 from "../../assets/images/event-details/NationalImage/Suresh-Nagar-Chaudhary.jpg";
import NationalImage22 from "../../assets/images/event-details/NationalImage/Barkha-Solanki.jpg";
import NationalImage23 from "../../assets/images/event-details/NationalImage/Ritesh-ranjan.jpg";
import NationalImage24 from "../../assets/images/event-details/NationalImage/shashikant-tripathi.jpg";
import NationalImage25 from "../../assets/images/event-details/NationalImage/Rajeev-Gupta.jpg";
import NationalImage26 from "../../assets/images/event-details/NationalImage/dinesh-bassi.jpg";
import NationalImage27 from "../../assets/images/event-details/NationalImage/Rajan-Solanki-new.jpg";
import NationalImage28 from "../../assets/images/event-details/NationalImage/Raja-Kumar.jpg";
import NationalImage29 from "../../assets/images/event-details/NationalImage/Shashank-Shekhar.jpg";

import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import "./PeopleBehindUs.css";
import Col from "react-bootstrap/Col";

export default function NationalTeam() {
  return (
    <Row className="mt-5 doml">
      <Col sm={6} md={3}>
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage} />
          <Card.Body className="pb-0">
            <Card.Title>Kr. Rajiv Ranjan Singh</Card.Title>
            <Card.Text className="m-0">Founder President</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3}>
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage9} />
          <Card.Body className="pb-0">
            <Card.Title>Adv. Aditya Chaudhary</Card.Title>
            <Card.Text className="m-0">
              Advocate, Supreme Court of India
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3}>
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Pratap Rao</Card.Title>
            <Card.Text className="m-0">Political & Social Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3}>
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Anil Pandey</Card.Title>
            <Card.Text className="m-0">Senior Journalist, New Delhi</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage5} />
          <Card.Body className="pb-0">
            <Card.Title>N V SubbaRaju</Card.Title>
            <Card.Text className="m-0">Social & Political Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage4} />
          <Card.Body className="pb-0">
            <Card.Title>Aditya Pinnapureddy</Card.Title>
            <Card.Text className="m-0">
              Entrepreneur & Social Commentator
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage11} />
          <Card.Body className="pb-0">
            <Card.Title>Bhavesh Nandan Jha</Card.Title>
            <Card.Text className="m-0">Film Maker, New Delhi</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Akhand Pratap Singh</Card.Title>
            <Card.Text className="m-0">Adv. Supreme Court of India</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage22} />
          <Card.Body className="pb-0">
            <Card.Title>Barkha Singh</Card.Title>
            <Card.Text className="m-0">Senior Follows Strategy & Diplomacy</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Dinesh Bassi</Card.Title>
            <Card.Text className="m-0">Media</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage21} />
          <Card.Body className="pb-0">
            <Card.Title>Suresh Nagar Chaudhary</Card.Title>
            <Card.Text className="m-0">Social & Political Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage28} />
          <Card.Body className="pb-0">
            <Card.Title>Raja Kumar</Card.Title>
            <Card.Text className="m-0">Media</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage18} />
          <Card.Body className="pb-0">
            <Card.Title>Samar Qureshi</Card.Title>
            <Card.Text className="m-0">
              Management & Strategy Consultant
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage21} />
          <Card.Body className="pb-0">
            <Card.Title>Suresh Nagar Chaudhary</Card.Title>
            <Card.Text className="m-0">Social & Political Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Pratap Rao</Card.Title>
            <Card.Text className="m-0">Political & Social Activist</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    

      <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
          <Card.Img variant="top" src={NationalImage26} />
          <Card.Body className="pb-0">
            <Card.Title>Bharat Merchant</Card.Title>
            <Card.Text className="m-0">ESG & Climate Change</Card.Text>
          </Card.Body>
        </Card>
      </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage2} />
            <Card.Body className="pb-0">
              <Card.Title>Adv. Manish Kumar Chaudhary</Card.Title>
              <Card.Text className="m-0">Advocate, Supreme Court of India</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* 
        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage3} />
            <Card.Body className="pb-0">
              <Card.Title>Adv. Ashutosh Kumar</Card.Title>
              <Card.Text className="m-0">Advocate, Supreme Court of India</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage6} />
            <Card.Body className="pb-0">
              <Card.Title>Jeet Singh Rathore</Card.Title>
              <Card.Text className="m-0">Financial Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* 
        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage8} />
            <Card.Body className="pb-0">
              <Card.Title>Poonam Kaura</Card.Title>
              <Card.Text className="m-0">Partner, Nangia & Co LLP</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage10} />
            <Card.Body className="pb-0">
              <Card.Title>Mehul Shah</Card.Title>
              <Card.Text className="m-0">Chairman Precise Group</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage12} />
            <Card.Body className="pb-0">
              <Card.Title>Amish Patel</Card.Title>
              <Card.Text className="m-0">Mumbai</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage13} />
            <Card.Body className="pb-0">
              <Card.Title>Bharat Shetty</Card.Title>
              <Card.Text className="m-0">Mumbai</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage14} />
            <Card.Body className="pb-0">
              <Card.Title>Vikram Chauhan</Card.Title>
              <Card.Text className="m-0">Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
      {/* 
        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage15} />
            <Card.Body className="pb-0">
              <Card.Title>Tarunbir Singh Sethi</Card.Title>
              <Card.Text className="m-0">Advertising Professional</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage16} />
            <Card.Body className="pb-0">
              <Card.Title>Venkita Subramaniam K.</Card.Title>
              <Card.Text className="m-0">Kerala</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage17} />
            <Card.Body className="pb-0">
              <Card.Title>Upender Singh</Card.Title>
              <Card.Text className="m-0">Social & Political Activist</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage18} />
            <Card.Body className="pb-0">
              <Card.Title>Syed Mahmood Ahmad</Card.Title>
              <Card.Text className="m-0">Management & Strategy Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage19} />
            <Card.Body className="pb-0">
              <Card.Title>Dr. Vishwas Valvi</Card.Title>
              <Card.Text className="m-0">Social & Political Activist</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage20} />
            <Card.Body className="pb-0">
              <Card.Title>Varun Juneja</Card.Title>
              <Card.Text className="m-0">Social Activist</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage23} />
            <Card.Body className="pb-0">
              <Card.Title>Ritesh Ranjan</Card.Title>
              <Card.Text className="m-0">IT Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage24} />
            <Card.Body className="pb-0">
              <Card.Title>Shashikant Mani Tripathi</Card.Title>
              <Card.Text className="m-0">IT Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}

      {/* <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage25} />
            <Card.Body className="pb-0">
              <Card.Title>Rajeev Gupta</Card.Title>
              <Card.Text className="m-0">IT Consultant</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        

        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage27} />
            <Card.Body className="pb-0">
              <Card.Title>Rajan Solanki</Card.Title>
              <Card.Text className="m-0">Press</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        


        <Col sm={6} md={3} className="pt-4">
        <Card className="box-sdow">
            <Card.Img variant="top" src={NationalImage29} />
            <Card.Body className="pb-0">
              <Card.Title>Shashank Shekhar</Card.Title>
              <Card.Text className="m-0">Company Secretary</Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
    </Row>
  );
}
